// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCe4ylwpDeorIj5F8D21qpN8jWdpVQyZhk",
  authDomain: "gasbillnewigl.firebaseapp.com",
  databaseURL: "https://gasbillnewigl-default-rtdb.firebaseio.com",
  projectId: "gasbillnewigl",
  storageBucket: "gasbillnewigl.appspot.com",
  messagingSenderId: "800181499351",
  appId: "1:800181499351:web:c371ac56ecad6bf171c792",
  measurementId: "G-3BZJMK2GHQ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };